import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getOfferTransitions } from '../../transactions/transactionHelpers';
import { isVariantsInUse } from '../../util/search';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { sendOfferEmailApi } from '../../util/api';
import { EMAIL_TEMPLATES } from '../../util/enums';

const { ACCEPT_OFFER, DECLINE_OFFER, COUNTER_OFFER } = EMAIL_TEMPLATES;

const {
  TRANSITION_SEND_OFFER,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_COUNTER_OFFER_BY_CUSTOMER,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER,
} = getOfferTransitions();

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const INBOX_PAGE_SIZE = 50;

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/OffersPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/OffersPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/OffersPage/FETCH_ORDERS_OR_SALES_ERROR';

export const UPDATE_OFFER_PROGRESS = 'app/OffersPage/UPDATE_OFFER_PROGRESS';
export const UPDATE_OFFER_SUCCESS = 'app/OffersPage/UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_ERROR = 'app/OffersPage/UPDATE_OFFER_ERROR';

const apiQueryParams = {
  lastTransitions: [
    TRANSITION_SEND_OFFER,
    TRANSITION_ACCEPT_OFFER,
    TRANSITION_DECLINE_OFFER,
    TRANSITION_COUNTER_OFFER,
    TRANSITION_COUNTER_OFFER_BY_CUSTOMER,
    TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
    TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER,
    TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
    TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER,
  ],
  include: [
    'listing',
    'listing.images',
    'provider',
    'provider.profileImage',
    'customer',
    'customer.profileImage',
    'booking',
  ],
  'fields.transaction': [
    'createdAt',
    'lastTransition',
    'lastTransitionedAt',
    'transitions',
    'payinTotal',
    'payoutTotal',
    'lineItems',
    'protectedData',
    'metadata',
  ],
  'fields.listing': ['title', 'description', 'publicData', 'price', 'images'],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
  'fields.image': [
    'variants.scaled-small',
    'variants.listing-card',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',
    'variants.square-small',
    'variants.square-small2x',
  ],
  page: 1,
  per_page: INBOX_PAGE_SIZE,
};

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  updateOfferInProgress: false,
  updateOfferSuccess: false,
  updateOfferError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_OFFER_PROGRESS:
      return { ...state, updateOfferInProgress: true };
    case UPDATE_OFFER_SUCCESS:
      return { ...state, updateOfferSuccess: true, updateOfferInProgress: false };
    case UPDATE_OFFER_ERROR:
      return { ...state, updateOfferError: true, updateOfferInProgress: false };
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const updateOfferProgress = () => ({
  type: UPDATE_OFFER_PROGRESS,
});

const updateOfferSuccess = () => ({
  type: UPDATE_OFFER_SUCCESS,
});
const updateOfferError = () => ({
  type: UPDATE_OFFER_ERROR,
});

// ================ Selectors ================ //

export const getUpdateOfferInProgress = state => {
  return state.OffersPage.updateOfferInProgress;
};

// ================ Thunks ================ //

const queryTransactions = async (dispatch, sdk, queryParams, successAction, errorAction) => {
  try {
    const response = await sdk.transactions.query(queryParams);
    dispatch(addMarketplaceEntities(response));
    dispatch(successAction(response));
    return response;
  } catch (e) {
    dispatch(errorAction(storableError(e)));
    throw e;
  }
};

const sendOfferEmails = async (transition, transaction) => {
  const { protectedData } = transaction?.attributes || {};
  const { offerParams, shippingDetails } = protectedData || {};
  let { guestTransactionParams } = offerParams || {};
  const guestEmail = shippingDetails?.email ?? guestTransactionParams?.guestEmail;

  guestTransactionParams = { ...guestTransactionParams, guestEmail };

  let templateId;
  switch (transition) {
    case TRANSITION_ACCEPT_OFFER:
    case TRANSITION_ACCEPT_AFTER_COUNTER_OFFER:
      templateId = ACCEPT_OFFER;
      break;

    case TRANSITION_DECLINE_OFFER:
    case TRANSITION_DECLINE_AFTER_COUNTER_OFFER:
      templateId = DECLINE_OFFER;
      break;

    case TRANSITION_COUNTER_OFFER:
      templateId = COUNTER_OFFER;
      break;

    default:
      return { success: false, message: 'Unknown transition type' };
  }

  if (templateId) {
    const response = await sendOfferEmailApi({
      templateId,
      guestTransactionParams,
      protectedData,
      transaction,
    });
    return response;
  }

  return { success: false, message: 'No templateId found' };
};

export const updateOffer = ({ id, transition, message, offerParams = null }) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(updateOfferProgress());
  const params = offerParams
    ? {
        protectedData: {
          offerParams,
        },
      }
    : {};

  try {
    const response = await sdk.transactions.transition(
      {
        id,
        transition,
        params,
      },
      { expand: true }
    );

    await queryTransactions(
      dispatch,
      sdk,
      apiQueryParams,
      fetchOrdersOrSalesSuccess,
      fetchOrdersOrSalesError
    );
    await dispatch(updateOfferSuccess());

    const responseMessage = await sendOfferEmails(transition, response?.data?.data);

    return responseMessage;
  } catch (error) {
    dispatch(updateOfferError(error));
  }
};

export const queryCurrentTxListing = (listingId, config) => async (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const isVariantsEnabled = isVariantsInUse(config);

  const includedParams = {
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const params = {
    id: listingId,
    ...includedParams,
  };

  const data = await sdk.listings.show(params);
  const listingFields = config?.listing?.listingFields;
  const sanitizeConfig = { listingFields };
  dispatch(addMarketplaceEntities(data, sanitizeConfig));
  return data;
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const queryParams = {
    ...apiQueryParams,
    page,
  };

  return queryTransactions(
    dispatch,
    sdk,
    queryParams,
    fetchOrdersOrSalesSuccess,
    fetchOrdersOrSalesError
  );
};